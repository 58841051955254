import React from 'react'

import { Link } from 'gatsby'

const Wizard = ({ backTo, children, nextTo, title }) => (
  <div className="container h-100 py-4">
  <div className="row h-100 align-items-center justify-content-center">
    <div className="col col-xl-10">
      <div className="card shadow-sm" style={{ height: '37rem' }}>
        {
          title &&
          <div className="card-header">
            <h4 className="card-title my-0">{title}</h4>
          </div>
        }
        <div className="card-body">
        {children}
        </div>
        {
          (nextTo || backTo) &&
          <div className="card-footer d-flex">
          { backTo && <Link className="btn btn-outline-secondary" to={backTo}>Back</Link> }
          { nextTo && <Link className="btn btn-primary ml-auto" to={nextTo}>Next</Link> }
          </div>
        }
      </div>
    </div>
  </div>
  </div>
)

export default Wizard
