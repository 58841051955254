import React from 'react'

import Layout from '../../components/layout'
import Wizard from '../../components/wizard'

import getFromSessionStorage from '../../helpers/getFromSessionStorage'

const PrototypePage = () => (
  <Layout>
    <Wizard>
      <div className="row h-100 align-items-center justify-content-center">
        <div className="col d-flex flex-column align-items-center">
        <h1><span role="img" alt="rocket" aria-label="rocket">🚀</span></h1>
        <h3>Organization created</h3>
        <p>Your new organization <b>{getFromSessionStorage('org-name')}</b> is ready to use</p>
        <button className="btn btn-success btn-large">Get started</button>
        </div>
      </div>
    </Wizard>
  </Layout>
)

export default PrototypePage
